export const scatterData = [
  {
    Height: 5.378134684276249,
    Diameter: 0.2837053102864515,
    Family: "apple",
  },
  {
    Height: 7.026770998894443,
    Diameter: 0.33207590604763576,
    Family: "apple",
  },
  {
    Height: 8.315904765022157,
    Diameter: 0.43373473004096097,
    Family: "apple",
  },
  {
    Height: 3.6498932725961946,
    Diameter: 0.340192927284109,
    Family: "apple",
  },
  {
    Height: 6.5075162679197005,
    Diameter: 0.4208133542045584,
    Family: "apple",
  },
  {
    Height: 8.348660609314411,
    Diameter: 0.2899813396901569,
    Family: "apple",
  },
  {
    Height: 9.421950227722382,
    Diameter: 0.20528286046244248,
    Family: "apple",
  },
  {
    Height: 7.896332074805976,
    Diameter: 0.2760452936212325,
    Family: "apple",
  },
  {
    Height: 8.991058052538946,
    Diameter: 0.323141279464649,
    Family: "apple",
  },
  {
    Height: 8.779807373618395,
    Diameter: 0.2794179616731622,
    Family: "apple",
  },
  {
    Height: 6.224027369482324,
    Diameter: 0.3377198782721952,
    Family: "apple",
  },
  {
    Height: 7.786999135105338,
    Diameter: 0.4162146742257026,
    Family: "apple",
  },
  {
    Height: 8.577082057259027,
    Diameter: 0.3920555558040337,
    Family: "apple",
  },
  {
    Height: 7.5783578760247075,
    Diameter: 0.3329767205122587,
    Family: "apple",
  },
  {
    Height: 5.401945172658548,
    Diameter: 0.3925712658993029,
    Family: "apple",
  },
  {
    Height: 6.424278009108987,
    Diameter: 0.3750560316196041,
    Family: "apple",
  },
  {
    Height: 6.306514509007707,
    Diameter: 0.2692666806586824,
    Family: "apple",
  },
  {
    Height: 5.979720750245472,
    Diameter: 0.4303423671955017,
    Family: "apple",
  },
  {
    Height: 8.501834143773902,
    Diameter: 0.4252007951459287,
    Family: "apple",
  },
  {
    Height: 9.735220833604087,
    Diameter: 0.33803794102265194,
    Family: "apple",
  },
  {
    Height: 9.849267838333237,
    Diameter: 0.3934263382447983,
    Family: "apple",
  },
  {
    Height: 9.82242930026342,
    Diameter: 0.3544905997693495,
    Family: "apple",
  },
  {
    Height: 8.126177454200544,
    Diameter: 0.4158384944385092,
    Family: "apple",
  },
  {
    Height: 7.363172332975688,
    Diameter: 0.4040126228810647,
    Family: "apple",
  },
  {
    Height: 8.092656815557994,
    Diameter: 0.3340260447537869,
    Family: "apple",
  },
  {
    Height: 4.921892414532857,
    Diameter: 0.3017574369773133,
    Family: "apple",
  },
  {
    Height: 7.750236378926506,
    Diameter: 0.3663964609236702,
    Family: "apple",
  },
  {
    Height: 5.732788443606039,
    Diameter: 0.4488555701323009,
    Family: "apple",
  },
  {
    Height: 7.605071013709472,
    Diameter: 0.4097062530380759,
    Family: "apple",
  },
  {
    Height: 5.471691020402937,
    Diameter: 0.2193367411597629,
    Family: "apple",
  },
  {
    Height: 8.410034944656987,
    Diameter: 0.4347781549566119,
    Family: "apple",
  },
  {
    Height: 6.637325469840594,
    Diameter: 0.3843695268210721,
    Family: "apple",
  },
  {
    Height: 4.432164474873146,
    Diameter: 0.4517772834518525,
    Family: "apple",
  },
  {
    Height: 6.194494791064825,
    Diameter: 0.3832607134883349,
    Family: "apple",
  },
  {
    Height: 5.989321892924825,
    Diameter: 0.4710700019892728,
    Family: "apple",
  },
  {
    Height: 8.52220649983437,
    Diameter: 0.2904503960247318,
    Family: "apple",
  },
  {
    Height: 8.98895597681797,
    Diameter: 0.3883776426016339,
    Family: "apple",
  },
  {
    Height: 7.230096142836385,
    Diameter: 0.3135821886374153,
    Family: "apple",
  },
  {
    Height: 7.5010032985521935,
    Diameter: 0.2367375937610627,
    Family: "apple",
  },
  {
    Height: 7.49576305209169,
    Diameter: 0.3475480209305752,
    Family: "apple",
  },
  {
    Height: 8.411007148238344,
    Diameter: 0.3798569219078376,
    Family: "apple",
  },
  {
    Height: 7.212326690968554,
    Diameter: 0.29644675976749674,
    Family: "apple",
  },
  {
    Height: 6.424278009108987,
    Diameter: 0.3750560316196041,
    Family: "apple",
  },
  {
    Height: 6.905481940324154,
    Diameter: 0.3517647873909887,
    Family: "apple",
  },
  {
    Height: 5.378071819273013,
    Diameter: 0.37397028012946376,
    Family: "apple",
  },
  {
    Height: 7.2809848018346655,
    Diameter: 0.35450359943840504,
    Family: "apple",
  },
  {
    Height: 7.204259921203327,
    Diameter: 0.4071277510533611,
    Family: "apple",
  },
  {
    Height: 4.921892414532857,
    Diameter: 0.3017574369773133,
    Family: "apple",
  },
  {
    Height: 6.984723580549742,
    Diameter: 0.41900967381187904,
    Family: "apple",
  },
  {
    Height: 4.958440299138612,
    Diameter: 0.2743102365785393,
    Family: "apple",
  },
  {
    Height: 6.826826364216133,
    Diameter: 0.19568658345953274,
    Family: "cherry",
  },
  {
    Height: 5.065276092143593,
    Diameter: 0.2887589999228476,
    Family: "cherry",
  },
  {
    Height: 4.175110869129972,
    Diameter: 0.2489974288615531,
    Family: "cherry",
  },
  {
    Height: 7.074215361800091,
    Diameter: 0.2401139133631077,
    Family: "cherry",
  },
  {
    Height: 3.504352636035516,
    Diameter: 0.21265902403110112,
    Family: "cherry",
  },
  {
    Height: 3.931601796372556,
    Diameter: 0.19385340552861086,
    Family: "cherry",
  },
  {
    Height: 2.959438941134493,
    Diameter: 0.21101741453335784,
    Family: "cherry",
  },
  {
    Height: 1.3531707739069665,
    Diameter: 0.15202589008824116,
    Family: "cherry",
  },
  {
    Height: 2.9274792177104287,
    Diameter: 0.2371221128734013,
    Family: "cherry",
  },
  {
    Height: 2.073448949712919,
    Diameter: 0.2008115885122318,
    Family: "cherry",
  },
  {
    Height: 3.115585938289557,
    Diameter: 0.2252644818572057,
    Family: "cherry",
  },
  {
    Height: 1.5074707027897296,
    Diameter: 0.1571428571245346,
    Family: "cherry",
  },
  {
    Height: 1.5154725718921578,
    Diameter: 0.11853452060967162,
    Family: "cherry",
  },
  {
    Height: 3.3844347545425566,
    Diameter: 0.2513000434586389,
    Family: "cherry",
  },
  {
    Height: 3.5235809697276257,
    Diameter: 0.19022830439439464,
    Family: "cherry",
  },
  {
    Height: 5.426264791051264,
    Diameter: 0.3139717250649179,
    Family: "cherry",
  },
  {
    Height: 3.94276337443783,
    Diameter: 0.18551767355748724,
    Family: "cherry",
  },
  {
    Height: 2.433817911566308,
    Diameter: 0.04309305046589432,
    Family: "cherry",
  },
  {
    Height: 3.861252157184413,
    Diameter: 0.10801291473337032,
    Family: "cherry",
  },
  {
    Height: 0.3642815418538148,
    Diameter: 0.09476508086494977,
    Family: "cherry",
  },
  {
    Height: 2.2969700138355917,
    Diameter: 0.1982897180086732,
    Family: "cherry",
  },
  {
    Height: 3.8925901160952687,
    Diameter: 0.12751817208343386,
    Family: "cherry",
  },
  {
    Height: 5.4599721608237255,
    Diameter: 0.2340065028283703,
    Family: "cherry",
  },
  {
    Height: 1.9531075997484997,
    Diameter: 0.10051743577828756,
    Family: "cherry",
  },
  {
    Height: 2.773729825320483,
    Diameter: 0.1994289061935656,
    Family: "cherry",
  },
  {
    Height: 3.6782932052493305,
    Diameter: 0.1673273776698777,
    Family: "cherry",
  },
  {
    Height: 5.169407927240174,
    Diameter: 0.2239210950462592,
    Family: "cherry",
  },
  {
    Height: 2.795045914244948,
    Diameter: 0.30311633433841306,
    Family: "cherry",
  },
  {
    Height: 4.841722458402736,
    Diameter: 0.2211556047130321,
    Family: "cherry",
  },
  {
    Height: 3.5725463559332185,
    Diameter: 0.28520802676934315,
    Family: "cherry",
  },
  {
    Height: 3.4137423619915466,
    Diameter: 0.260347239443432,
    Family: "cherry",
  },
  {
    Height: 3.152548047623573,
    Diameter: 0.08844164384204815,
    Family: "cherry",
  },
  {
    Height: 2.4007303551539363,
    Diameter: 0.1182317310962694,
    Family: "cherry",
  },
  {
    Height: 3.4017360151144023,
    Diameter: 0.3047867286857835,
    Family: "cherry",
  },
  {
    Height: 1.905556279838584,
    Diameter: 0.12056707362043716,
    Family: "cherry",
  },
  {
    Height: 3.3759817239673984,
    Diameter: 0.12915788665196262,
    Family: "cherry",
  },
  {
    Height: 2.829831023475418,
    Diameter: 0.1936803347539328,
    Family: "cherry",
  },
  {
    Height: 2.7475307491294645,
    Diameter: 0.18853204879992685,
    Family: "cherry",
  },
  {
    Height: 3.8436354572087694,
    Diameter: 0.2604042284939499,
    Family: "cherry",
  },
  {
    Height: 4.608487593711247,
    Diameter: 0.20136528630476572,
    Family: "cherry",
  },
  {
    Height: 1.964452064584164,
    Diameter: 0.2189140901790427,
    Family: "cherry",
  },
  {
    Height: 5.7468323469296925,
    Diameter: 0.299745004207425,
    Family: "cherry",
  },
  {
    Height: 1.757258093276727,
    Diameter: 0.2000163992318788,
    Family: "cherry",
  },
  {
    Height: 2.5130873186563254,
    Diameter: 0.2269506516773156,
    Family: "cherry",
  },
  {
    Height: 3.927015263919292,
    Diameter: 0.19422445207977626,
    Family: "cherry",
  },
  {
    Height: 2.8472092579223833,
    Diameter: 0.10098289382131112,
    Family: "cherry",
  },
  {
    Height: 4.01046579771699,
    Diameter: 0.1912596593865531,
    Family: "cherry",
  },
  {
    Height: 4.561842693058842,
    Diameter: 0.21209319101039825,
    Family: "cherry",
  },
  {
    Height: 1.921243710658904,
    Diameter: 0.1972398426723843,
    Family: "cherry",
  },
  {
    Height: 2.3807114937249607,
    Diameter: 0.17469612837406315,
    Family: "cherry",
  },
  {
    Height: 8.393851039719372,
    Diameter: 0.4706168851814446,
    Family: "oak",
  },
  {
    Height: 13.0836550644812,
    Diameter: 0.5630717411309293,
    Family: "oak",
  },
  {
    Height: 3.652526786406281,
    Diameter: 0.8572696732141827,
    Family: "oak",
  },
  {
    Height: 10.609924889061622,
    Diameter: 0.8094630719246008,
    Family: "oak",
  },
  {
    Height: 10.766935120680039,
    Diameter: 1.0030103882834824,
    Family: "oak",
  },
  {
    Height: 10.585886155036047,
    Diameter: 0.818702551577114,
    Family: "oak",
  },
  {
    Height: 3.362396467024424,
    Diameter: 0.7441798977354616,
    Family: "oak",
  },
  {
    Height: 3.5773458846406223,
    Diameter: 0.5565087271923822,
    Family: "oak",
  },
  {
    Height: 11.470997466737554,
    Diameter: 0.9434900517744736,
    Family: "oak",
  },
  {
    Height: 7.1340941717084645,
    Diameter: 0.45034198652389534,
    Family: "oak",
  },
  {
    Height: 8.994619858015309,
    Diameter: 0.9651364379507832,
    Family: "oak",
  },
  {
    Height: 11.155333188250728,
    Diameter: 0.7381067384039341,
    Family: "oak",
  },
  {
    Height: 12.003398948581589,
    Diameter: 0.4921529271634439,
    Family: "oak",
  },
  {
    Height: 3.314760353468839,
    Diameter: 0.5527175232488952,
    Family: "oak",
  },
  {
    Height: 6.9525372257303975,
    Diameter: 0.6505191539262914,
    Family: "oak",
  },
  {
    Height: 14.308553948019547,
    Diameter: 0.4595230403418795,
    Family: "oak",
  },
  {
    Height: 5.318455859550399,
    Diameter: 0.6358755271977429,
    Family: "oak",
  },
  {
    Height: 8.994619858015309,
    Diameter: 0.9651364379507832,
    Family: "oak",
  },
  {
    Height: 8.078812414828402,
    Diameter: 0.9282895060879556,
    Family: "oak",
  },
  {
    Height: 4.9547992790837965,
    Diameter: 0.7868729371518406,
    Family: "oak",
  },
  {
    Height: 8.931685913355011,
    Diameter: 0.5205977935304034,
    Family: "oak",
  },
  {
    Height: 5.190725035438217,
    Diameter: 0.5604360140691054,
    Family: "oak",
  },
  {
    Height: 6.360206365465713,
    Diameter: 0.7841339180006817,
    Family: "oak",
  },
  {
    Height: 6.9987725888175145,
    Diameter: 0.87953525916728,
    Family: "oak",
  },
  {
    Height: 6.798935852859628,
    Diameter: 0.4777269986924233,
    Family: "oak",
  },
  {
    Height: 7.319591483488012,
    Diameter: 0.8969869229949456,
    Family: "oak",
  },
  {
    Height: 10.13716832344025,
    Diameter: 0.5038617070681707,
    Family: "oak",
  },
  {
    Height: 3.4394512021969,
    Diameter: 0.5463333491486161,
    Family: "oak",
  },
  {
    Height: 7.688511914462794,
    Diameter: 0.5018764223796339,
    Family: "oak",
  },
  {
    Height: 6.02251194007707,
    Diameter: 0.7468364003689868,
    Family: "oak",
  },
  {
    Height: 3.1136905750870767,
    Diameter: 0.7680268494456803,
    Family: "oak",
  },
  {
    Height: 3.2178521278717245,
    Diameter: 0.6041827152002488,
    Family: "oak",
  },
  {
    Height: 7.820712401319972,
    Diameter: 0.8373904607420403,
    Family: "oak",
  },
  {
    Height: 10.336012182523184,
    Diameter: 0.8864125069166998,
    Family: "oak",
  },
  {
    Height: 14.03734728401795,
    Diameter: 0.6391162313019731,
    Family: "oak",
  },
  {
    Height: 7.068698596298202,
    Diameter: 0.49088943260977796,
    Family: "oak",
  },
  {
    Height: 11.321980826168046,
    Diameter: 0.7892516270462221,
    Family: "oak",
  },
  {
    Height: 11.642967839528112,
    Diameter: 0.5446381743548243,
    Family: "oak",
  },
  {
    Height: 3.685530543208692,
    Diameter: 0.5013497707282457,
    Family: "oak",
  },
  {
    Height: 11.976932228515894,
    Diameter: 0.6774724184486708,
    Family: "oak",
  },
  {
    Height: 11.563539032354994,
    Diameter: 0.5580426173913396,
    Family: "oak",
  },
  {
    Height: 3.3539553088448937,
    Diameter: 0.8054232187468592,
    Family: "oak",
  },
  {
    Height: 10.158280227696151,
    Diameter: 0.9705464856167308,
    Family: "oak",
  },
  {
    Height: 12.172466643630367,
    Diameter: 0.6108372959361905,
    Family: "oak",
  },
  {
    Height: 7.864043672934394,
    Diameter: 0.4706209249845933,
    Family: "oak",
  },
  {
    Height: 12.999214026430275,
    Diameter: 0.9603531223531292,
    Family: "oak",
  },
  {
    Height: 10.13736347650017,
    Diameter: 0.8554993487517485,
    Family: "oak",
  },
  {
    Height: 9.861459738053707,
    Diameter: 0.9749291373360676,
    Family: "oak",
  },
  {
    Height: 9.340502063797416,
    Diameter: 0.5477997053852709,
    Family: "oak",
  },
  {
    Height: 7.2765669488404345,
    Diameter: 0.7063986424618351,
    Family: "oak",
  },
];

// data for entropy scatter chart showing information gain
export const informationGainData = [
  { Diameter: 0.043, ent_left: 0.0, ent_right: 1.099, ig: 0.0 },
  { Diameter: 0.046, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.05, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.053, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.056, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.059, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.062, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.066, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.069, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.072, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.075, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.078, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.082, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.085, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.088, ent_left: 0.0, ent_right: 1.099, ig: 0.007 },
  { Diameter: 0.091, ent_left: 0.0, ent_right: 1.098, ig: 0.015 },
  { Diameter: 0.094, ent_left: 0.0, ent_right: 1.098, ig: 0.015 },
  { Diameter: 0.098, ent_left: 0.0, ent_right: 1.098, ig: 0.022 },
  { Diameter: 0.101, ent_left: 0.0, ent_right: 1.098, ig: 0.03 },
  { Diameter: 0.104, ent_left: 0.0, ent_right: 1.097, ig: 0.038 },
  { Diameter: 0.107, ent_left: 0.0, ent_right: 1.097, ig: 0.038 },
  { Diameter: 0.111, ent_left: 0.0, ent_right: 1.097, ig: 0.046 },
  { Diameter: 0.114, ent_left: 0.0, ent_right: 1.097, ig: 0.046 },
  { Diameter: 0.117, ent_left: 0.0, ent_right: 1.097, ig: 0.046 },
  { Diameter: 0.12, ent_left: 0.0, ent_right: 1.095, ig: 0.062 },
  { Diameter: 0.123, ent_left: 0.0, ent_right: 1.094, ig: 0.07 },
  { Diameter: 0.127, ent_left: 0.0, ent_right: 1.094, ig: 0.07 },
  { Diameter: 0.13, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.133, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.136, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.139, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.143, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.146, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.149, ent_left: 0.0, ent_right: 1.092, ig: 0.087 },
  { Diameter: 0.152, ent_left: 0.0, ent_right: 1.091, ig: 0.095 },
  { Diameter: 0.155, ent_left: 0.0, ent_right: 1.091, ig: 0.095 },
  { Diameter: 0.159, ent_left: 0.0, ent_right: 1.089, ig: 0.104 },
  { Diameter: 0.162, ent_left: 0.0, ent_right: 1.089, ig: 0.104 },
  { Diameter: 0.165, ent_left: 0.0, ent_right: 1.089, ig: 0.104 },
  { Diameter: 0.168, ent_left: 0.0, ent_right: 1.088, ig: 0.113 },
  { Diameter: 0.172, ent_left: 0.0, ent_right: 1.088, ig: 0.113 },
  { Diameter: 0.175, ent_left: 0.0, ent_right: 1.086, ig: 0.121 },
  { Diameter: 0.178, ent_left: 0.0, ent_right: 1.086, ig: 0.121 },
  { Diameter: 0.181, ent_left: 0.0, ent_right: 1.086, ig: 0.121 },
  { Diameter: 0.184, ent_left: 0.0, ent_right: 1.086, ig: 0.121 },
  { Diameter: 0.188, ent_left: 0.0, ent_right: 1.084, ig: 0.131 },
  { Diameter: 0.191, ent_left: 0.0, ent_right: 1.079, ig: 0.149 },
  { Diameter: 0.194, ent_left: 0.0, ent_right: 1.07, ig: 0.178 },
  { Diameter: 0.197, ent_left: 0.0, ent_right: 1.063, ig: 0.198 },
  { Diameter: 0.2, ent_left: 0.0, ent_right: 1.045, ig: 0.241 },
  { Diameter: 0.204, ent_left: 0.0, ent_right: 1.034, ig: 0.264 },
  { Diameter: 0.207, ent_left: 0.146, ent_right: 1.036, ig: 0.241 },
  { Diameter: 0.21, ent_left: 0.146, ent_right: 1.036, ig: 0.241 },
  { Diameter: 0.213, ent_left: 0.136, ent_right: 1.016, ig: 0.276 },
  { Diameter: 0.216, ent_left: 0.136, ent_right: 1.016, ig: 0.276 },
  { Diameter: 0.22, ent_left: 0.219, ent_right: 1.009, ig: 0.274 },
  { Diameter: 0.223, ent_left: 0.215, ent_right: 1.001, ig: 0.286 },
  { Diameter: 0.226, ent_left: 0.206, ent_right: 0.983, ig: 0.312 },
  { Diameter: 0.229, ent_left: 0.202, ent_right: 0.973, ig: 0.326 },
  { Diameter: 0.233, ent_left: 0.202, ent_right: 0.973, ig: 0.326 },
  { Diameter: 0.236, ent_left: 0.199, ent_right: 0.962, ig: 0.34 },
  { Diameter: 0.239, ent_left: 0.257, ent_right: 0.951, ig: 0.342 },
  { Diameter: 0.242, ent_left: 0.253, ent_right: 0.938, ig: 0.357 },
  { Diameter: 0.245, ent_left: 0.253, ent_right: 0.938, ig: 0.357 },
  { Diameter: 0.249, ent_left: 0.253, ent_right: 0.938, ig: 0.357 },
  { Diameter: 0.252, ent_left: 0.245, ent_right: 0.909, ig: 0.389 },
  { Diameter: 0.255, ent_left: 0.245, ent_right: 0.909, ig: 0.389 },
  { Diameter: 0.258, ent_left: 0.245, ent_right: 0.909, ig: 0.389 },
  { Diameter: 0.261, ent_left: 0.237, ent_right: 0.874, ig: 0.424 },
  { Diameter: 0.265, ent_left: 0.237, ent_right: 0.874, ig: 0.424 },
  { Diameter: 0.268, ent_left: 0.237, ent_right: 0.874, ig: 0.424 },
  { Diameter: 0.271, ent_left: 0.287, ent_right: 0.875, ig: 0.412 },
  { Diameter: 0.274, ent_left: 0.287, ent_right: 0.875, ig: 0.412 },
  { Diameter: 0.277, ent_left: 0.367, ent_right: 0.877, ig: 0.392 },
  { Diameter: 0.281, ent_left: 0.4, ent_right: 0.877, ig: 0.384 },
  { Diameter: 0.284, ent_left: 0.429, ent_right: 0.877, ig: 0.376 },
  { Diameter: 0.287, ent_left: 0.424, ent_right: 0.857, ig: 0.395 },
  { Diameter: 0.29, ent_left: 0.446, ent_right: 0.834, ig: 0.407 },
  { Diameter: 0.294, ent_left: 0.469, ent_right: 0.834, ig: 0.401 },
  { Diameter: 0.297, ent_left: 0.491, ent_right: 0.833, ig: 0.396 },
  { Diameter: 0.3, ent_left: 0.486, ent_right: 0.807, ig: 0.416 },
  { Diameter: 0.303, ent_left: 0.518, ent_right: 0.774, ig: 0.429 },
  { Diameter: 0.306, ent_left: 0.514, ent_right: 0.736, ig: 0.454 },
  { Diameter: 0.31, ent_left: 0.514, ent_right: 0.736, ig: 0.454 },
  { Diameter: 0.313, ent_left: 0.514, ent_right: 0.736, ig: 0.454 },
  { Diameter: 0.316, ent_left: 0.525, ent_right: 0.68, ig: 0.485 },
  { Diameter: 0.319, ent_left: 0.525, ent_right: 0.68, ig: 0.485 },
  { Diameter: 0.322, ent_left: 0.525, ent_right: 0.68, ig: 0.485 },
  { Diameter: 0.326, ent_left: 0.54, ent_right: 0.677, ig: 0.481 },
  { Diameter: 0.329, ent_left: 0.54, ent_right: 0.677, ig: 0.481 },
  { Diameter: 0.332, ent_left: 0.54, ent_right: 0.677, ig: 0.481 },
  { Diameter: 0.335, ent_left: 0.578, ent_right: 0.669, ig: 0.471 },
  { Diameter: 0.338, ent_left: 0.598, ent_right: 0.662, ig: 0.467 },
  { Diameter: 0.342, ent_left: 0.607, ent_right: 0.657, ig: 0.465 },
  { Diameter: 0.345, ent_left: 0.607, ent_right: 0.657, ig: 0.465 },
  { Diameter: 0.348, ent_left: 0.615, ent_right: 0.653, ig: 0.464 },
  { Diameter: 0.351, ent_left: 0.615, ent_right: 0.653, ig: 0.464 },
  { Diameter: 0.355, ent_left: 0.637, ent_right: 0.637, ig: 0.462 },
  { Diameter: 0.358, ent_left: 0.637, ent_right: 0.637, ig: 0.462 },
  { Diameter: 0.361, ent_left: 0.637, ent_right: 0.637, ig: 0.462 },
  { Diameter: 0.364, ent_left: 0.637, ent_right: 0.637, ig: 0.462 },
  { Diameter: 0.367, ent_left: 0.642, ent_right: 0.63, ig: 0.462 },
  { Diameter: 0.371, ent_left: 0.642, ent_right: 0.63, ig: 0.462 },
  { Diameter: 0.374, ent_left: 0.642, ent_right: 0.63, ig: 0.462 },
  { Diameter: 0.377, ent_left: 0.657, ent_right: 0.607, ig: 0.465 },
  { Diameter: 0.38, ent_left: 0.662, ent_right: 0.598, ig: 0.467 },
  { Diameter: 0.383, ent_left: 0.665, ent_right: 0.589, ig: 0.469 },
  { Diameter: 0.387, ent_left: 0.669, ent_right: 0.578, ig: 0.471 },
  { Diameter: 0.39, ent_left: 0.672, ent_right: 0.566, ig: 0.474 },
  { Diameter: 0.393, ent_left: 0.677, ent_right: 0.54, ig: 0.481 },
  { Diameter: 0.396, ent_left: 0.68, ent_right: 0.525, ig: 0.485 },
  { Diameter: 0.399, ent_left: 0.68, ent_right: 0.525, ig: 0.485 },
  { Diameter: 0.403, ent_left: 0.68, ent_right: 0.525, ig: 0.485 },
  { Diameter: 0.406, ent_left: 0.682, ent_right: 0.509, ig: 0.489 },
  { Diameter: 0.409, ent_left: 0.684, ent_right: 0.491, ig: 0.494 },
  { Diameter: 0.412, ent_left: 0.685, ent_right: 0.472, ig: 0.5 },
  { Diameter: 0.416, ent_left: 0.685, ent_right: 0.472, ig: 0.5 },
  { Diameter: 0.419, ent_left: 0.688, ent_right: 0.427, ig: 0.513 },
  { Diameter: 0.422, ent_left: 0.69, ent_right: 0.372, ig: 0.529 },
  { Diameter: 0.425, ent_left: 0.69, ent_right: 0.372, ig: 0.529 },
  { Diameter: 0.428, ent_left: 0.691, ent_right: 0.34, ig: 0.538 },
  { Diameter: 0.432, ent_left: 0.692, ent_right: 0.305, ig: 0.549 },
  { Diameter: 0.435, ent_left: 0.692, ent_right: 0.264, ig: 0.56 },
  { Diameter: 0.438, ent_left: 0.693, ent_right: 0.218, ig: 0.574 },
  { Diameter: 0.441, ent_left: 0.693, ent_right: 0.218, ig: 0.574 },
  { Diameter: 0.444, ent_left: 0.693, ent_right: 0.218, ig: 0.574 },
  { Diameter: 0.448, ent_left: 0.693, ent_right: 0.218, ig: 0.574 },
  { Diameter: 0.451, ent_left: 0.742, ent_right: 0.165, ig: 0.552 },
  { Diameter: 0.454, ent_left: 0.742, ent_right: 0.098, ig: 0.571 },
  { Diameter: 0.457, ent_left: 0.742, ent_right: 0.098, ig: 0.571 },
  { Diameter: 0.46, ent_left: 0.777, ent_right: 0.1, ig: 0.543 },
  { Diameter: 0.464, ent_left: 0.777, ent_right: 0.1, ig: 0.543 },
  { Diameter: 0.467, ent_left: 0.777, ent_right: 0.1, ig: 0.543 },
  { Diameter: 0.47, ent_left: 0.777, ent_right: 0.1, ig: 0.543 },
  { Diameter: 0.473, ent_left: 0.83, ent_right: 0.0, ig: 0.523 },
  { Diameter: 0.477, ent_left: 0.83, ent_right: 0.0, ig: 0.523 },
  { Diameter: 0.48, ent_left: 0.852, ent_right: 0.0, ig: 0.503 },
  { Diameter: 0.483, ent_left: 0.852, ent_right: 0.0, ig: 0.503 },
  { Diameter: 0.486, ent_left: 0.852, ent_right: 0.0, ig: 0.503 },
  { Diameter: 0.489, ent_left: 0.852, ent_right: 0.0, ig: 0.503 },
  { Diameter: 0.493, ent_left: 0.889, ent_right: 0.0, ig: 0.464 },
  { Diameter: 0.496, ent_left: 0.889, ent_right: 0.0, ig: 0.464 },
  { Diameter: 0.499, ent_left: 0.889, ent_right: 0.0, ig: 0.464 },
  { Diameter: 0.502, ent_left: 0.921, ent_right: 0.0, ig: 0.429 },
  { Diameter: 0.505, ent_left: 0.935, ent_right: 0.0, ig: 0.413 },
  { Diameter: 0.509, ent_left: 0.935, ent_right: 0.0, ig: 0.413 },
  { Diameter: 0.512, ent_left: 0.935, ent_right: 0.0, ig: 0.413 },
  { Diameter: 0.515, ent_left: 0.935, ent_right: 0.0, ig: 0.413 },
  { Diameter: 0.518, ent_left: 0.935, ent_right: 0.0, ig: 0.413 },
  { Diameter: 0.521, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.525, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.528, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.531, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.534, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.537, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.541, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.544, ent_left: 0.948, ent_right: 0.0, ig: 0.397 },
  { Diameter: 0.547, ent_left: 0.97, ent_right: 0.0, ig: 0.368 },
  { Diameter: 0.55, ent_left: 0.981, ent_right: 0.0, ig: 0.353 },
  { Diameter: 0.554, ent_left: 0.99, ent_right: 0.0, ig: 0.34 },
  { Diameter: 0.557, ent_left: 0.999, ent_right: 0.0, ig: 0.326 },
  { Diameter: 0.56, ent_left: 1.007, ent_right: 0.0, ig: 0.313 },
  { Diameter: 0.563, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.566, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.57, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.573, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.576, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.579, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.582, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.586, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.589, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.592, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.595, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.598, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.602, ent_left: 1.022, ent_right: 0.0, ig: 0.288 },
  { Diameter: 0.605, ent_left: 1.028, ent_right: 0.0, ig: 0.276 },
  { Diameter: 0.608, ent_left: 1.028, ent_right: 0.0, ig: 0.276 },
  { Diameter: 0.611, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.615, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.618, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.621, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.624, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.627, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.631, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.634, ent_left: 1.034, ent_right: 0.0, ig: 0.264 },
  { Diameter: 0.637, ent_left: 1.04, ent_right: 0.0, ig: 0.253 },
  { Diameter: 0.64, ent_left: 1.045, ent_right: 0.0, ig: 0.241 },
  { Diameter: 0.643, ent_left: 1.045, ent_right: 0.0, ig: 0.241 },
  { Diameter: 0.647, ent_left: 1.045, ent_right: 0.0, ig: 0.241 },
  { Diameter: 0.65, ent_left: 1.045, ent_right: 0.0, ig: 0.241 },
  { Diameter: 0.653, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.656, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.659, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.663, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.666, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.669, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.672, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.676, ent_left: 1.05, ent_right: 0.0, ig: 0.23 },
  { Diameter: 0.679, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.682, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.685, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.688, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.692, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.695, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.698, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.701, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.704, ent_left: 1.055, ent_right: 0.0, ig: 0.22 },
  { Diameter: 0.708, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.711, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.714, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.717, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.72, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.724, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.727, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.73, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.733, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.737, ent_left: 1.059, ent_right: 0.0, ig: 0.209 },
  { Diameter: 0.74, ent_left: 1.063, ent_right: 0.0, ig: 0.198 },
  { Diameter: 0.743, ent_left: 1.063, ent_right: 0.0, ig: 0.198 },
  { Diameter: 0.746, ent_left: 1.067, ent_right: 0.0, ig: 0.188 },
  { Diameter: 0.749, ent_left: 1.07, ent_right: 0.0, ig: 0.178 },
  { Diameter: 0.753, ent_left: 1.07, ent_right: 0.0, ig: 0.178 },
  { Diameter: 0.756, ent_left: 1.07, ent_right: 0.0, ig: 0.178 },
  { Diameter: 0.759, ent_left: 1.07, ent_right: 0.0, ig: 0.178 },
  { Diameter: 0.762, ent_left: 1.07, ent_right: 0.0, ig: 0.178 },
  { Diameter: 0.765, ent_left: 1.07, ent_right: 0.0, ig: 0.178 },
  { Diameter: 0.769, ent_left: 1.073, ent_right: 0.0, ig: 0.168 },
  { Diameter: 0.772, ent_left: 1.073, ent_right: 0.0, ig: 0.168 },
  { Diameter: 0.775, ent_left: 1.073, ent_right: 0.0, ig: 0.168 },
  { Diameter: 0.778, ent_left: 1.073, ent_right: 0.0, ig: 0.168 },
  { Diameter: 0.781, ent_left: 1.073, ent_right: 0.0, ig: 0.168 },
  { Diameter: 0.785, ent_left: 1.076, ent_right: 0.0, ig: 0.159 },
  { Diameter: 0.788, ent_left: 1.079, ent_right: 0.0, ig: 0.149 },
  { Diameter: 0.791, ent_left: 1.081, ent_right: 0.0, ig: 0.14 },
  { Diameter: 0.794, ent_left: 1.081, ent_right: 0.0, ig: 0.14 },
  { Diameter: 0.798, ent_left: 1.081, ent_right: 0.0, ig: 0.14 },
  { Diameter: 0.801, ent_left: 1.081, ent_right: 0.0, ig: 0.14 },
  { Diameter: 0.804, ent_left: 1.081, ent_right: 0.0, ig: 0.14 },
  { Diameter: 0.807, ent_left: 1.084, ent_right: 0.0, ig: 0.131 },
  { Diameter: 0.81, ent_left: 1.086, ent_right: 0.0, ig: 0.121 },
  { Diameter: 0.814, ent_left: 1.086, ent_right: 0.0, ig: 0.121 },
  { Diameter: 0.817, ent_left: 1.086, ent_right: 0.0, ig: 0.121 },
  { Diameter: 0.82, ent_left: 1.088, ent_right: 0.0, ig: 0.113 },
  { Diameter: 0.823, ent_left: 1.088, ent_right: 0.0, ig: 0.113 },
  { Diameter: 0.826, ent_left: 1.088, ent_right: 0.0, ig: 0.113 },
  { Diameter: 0.83, ent_left: 1.088, ent_right: 0.0, ig: 0.113 },
  { Diameter: 0.833, ent_left: 1.088, ent_right: 0.0, ig: 0.113 },
  { Diameter: 0.836, ent_left: 1.088, ent_right: 0.0, ig: 0.113 },
  { Diameter: 0.839, ent_left: 1.089, ent_right: 0.0, ig: 0.104 },
  { Diameter: 0.842, ent_left: 1.089, ent_right: 0.0, ig: 0.104 },
  { Diameter: 0.846, ent_left: 1.089, ent_right: 0.0, ig: 0.104 },
  { Diameter: 0.849, ent_left: 1.089, ent_right: 0.0, ig: 0.104 },
  { Diameter: 0.852, ent_left: 1.089, ent_right: 0.0, ig: 0.104 },
  { Diameter: 0.855, ent_left: 1.089, ent_right: 0.0, ig: 0.104 },
  { Diameter: 0.859, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.862, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.865, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.868, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.871, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.875, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.878, ent_left: 1.092, ent_right: 0.0, ig: 0.087 },
  { Diameter: 0.881, ent_left: 1.093, ent_right: 0.0, ig: 0.078 },
  { Diameter: 0.884, ent_left: 1.093, ent_right: 0.0, ig: 0.078 },
  { Diameter: 0.887, ent_left: 1.094, ent_right: 0.0, ig: 0.07 },
  { Diameter: 0.891, ent_left: 1.094, ent_right: 0.0, ig: 0.07 },
  { Diameter: 0.894, ent_left: 1.094, ent_right: 0.0, ig: 0.07 },
  { Diameter: 0.897, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.9, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.903, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.907, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.91, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.913, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.916, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.92, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.923, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.926, ent_left: 1.095, ent_right: 0.0, ig: 0.062 },
  { Diameter: 0.929, ent_left: 1.096, ent_right: 0.0, ig: 0.054 },
  { Diameter: 0.932, ent_left: 1.096, ent_right: 0.0, ig: 0.054 },
  { Diameter: 0.936, ent_left: 1.096, ent_right: 0.0, ig: 0.054 },
  { Diameter: 0.939, ent_left: 1.096, ent_right: 0.0, ig: 0.054 },
  { Diameter: 0.942, ent_left: 1.096, ent_right: 0.0, ig: 0.054 },
  { Diameter: 0.945, ent_left: 1.097, ent_right: 0.0, ig: 0.046 },
  { Diameter: 0.948, ent_left: 1.097, ent_right: 0.0, ig: 0.046 },
  { Diameter: 0.952, ent_left: 1.097, ent_right: 0.0, ig: 0.046 },
  { Diameter: 0.955, ent_left: 1.097, ent_right: 0.0, ig: 0.046 },
  { Diameter: 0.958, ent_left: 1.097, ent_right: 0.0, ig: 0.046 },
  { Diameter: 0.961, ent_left: 1.097, ent_right: 0.0, ig: 0.038 },
  { Diameter: 0.964, ent_left: 1.097, ent_right: 0.0, ig: 0.038 },
  { Diameter: 0.968, ent_left: 1.098, ent_right: 0.0, ig: 0.022 },
  { Diameter: 0.971, ent_left: 1.098, ent_right: 0.0, ig: 0.015 },
  { Diameter: 0.974, ent_left: 1.098, ent_right: 0.0, ig: 0.015 },
  { Diameter: 0.977, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 0.981, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 0.984, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 0.987, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 0.99, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 0.993, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 0.997, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 1.0, ent_left: 1.099, ent_right: 0.0, ig: 0.007 },
  { Diameter: 1.003, ent_left: 1.099, ent_right: 0.0, ig: 0.0 },
];

// data representing rectangles draw on scatterplot
// 1-indexed because first step to show nothing
export const splitRects = [
  {
    name: "Diameter \u2264 0.45",
    feature: "Diameter",
    value: 0.45,
    x1: 0.45,
    x2: 1.3,
    y1: 16,
    y2: 0,
    depth: 1,
    prediction: "oak",
  },
  {
    name: "Height \u2264 4.88",
    feature: "Height",
    value: 4.88,
    x1: 0,
    x2: 0.45,
    y1: 4.88,
    y2: 0,
    depth: 2,
    prediction: "cherry",
  },
  {
    name: "Diameter \u2264 0.32",
    feature: "Diameter",
    value: 0.32,
    x1: 0.32,
    x2: 0.45,
    y1: 16,
    y2: 4.88,
    depth: 3,
    prediction: "apple",
  },
  {
    name: "Height \u2264 7.14",
    feature: "Height",
    value: 7.14,
    x1: 0,
    x2: 0.32,
    y1: 16,
    y2: 7.14,
    depth: 4,
    prediction: "apple",
  },
  {
    name: "Height \u2264 7.14",
    feature: "Height",
    value: 7.14,
    x1: 0,
    x2: 0.32,
    y1: 7.14,
    y2: 4.88,
    depth: 4,
    prediction: "cherry",
  },
];

export const entropyTreeData = [
  {
    id: 0,
    entropy: "1.585",
    samples: 150,
    nApple: 50,
    nCherry: 50,
    nOak: 50,
    name: "Diameter \u2264 0.45",
  },
  {
    id: 1,
    entropy: "1.0",
    samples: 98,
    nApple: 48,
    nCherry: 50,
    nOak: 0,
    name: "Height \u2264 4.88",
  },
  {
    id: 2,
    entropy: "0.235",
    samples: 52,
    nApple: 2,
    nCherry: 0,
    nOak: 50,
    class: "oak",
  },
  {
    id: 3,
    entropy: "0.156",
    samples: 44,
    nApple: 1,
    nCherry: 43,
    nOak: 0,
    class: "cherry",
  },
  {
    id: 4,
    entropy: "0.556",
    samples: 54,
    nApple: 47,
    nCherry: 7,
    nOak: 0,
    name: "Diameter \u2264 0.32",
  },
  {
    id: 5,
    entropy: "0.918",
    samples: 21,
    nApple: 14,
    nCherry: 7,
    nOak: 0,
    name: "Height \u2264 7.14",
  },
  {
    id: 6,
    entropy: "0.0",
    samples: 33,
    nApple: 33,
    nCherry: 0,
    nOak: 0,
    class: "apple",
  },
  {
    id: 7,
    entropy: "0.996",
    samples: 13,
    nApple: 6,
    nCherry: 7,
    nOak: 0,
    class: "cherry",
  },
  {
    id: 8,
    entropy: "0.0",
    samples: 8,
    nApple: 8,
    nCherry: 0,
    nOak: 0,
    class: "apple",
  },
];

export const treeData = {
  id: "0",
  children: [
    {
      id: "1",
      children: [
        {
          id: "2",
          entropy: "0.156",
          samples: "44",
          value: "[ 1. 43.  0.]",
          class: "1",
        },
        {
          id: "5",
          children: [
            {
              id: "6",
              children: [
                {
                  id: "7",
                  entropy: "0.996",
                  samples: "13",
                  value: "[6. 7. 0.]",
                  class: "1",
                },
                {
                  id: "8",
                  entropy: "0.0",
                  samples: "8",
                  value: "[8. 0. 0.]",
                  class: "0",
                },
              ],
              name: "Height \u2264 7.14",
              entropy: "0.918",
              samples: "21",
            },
            {
              id: "9",
              entropy: "0.0",
              samples: "33",
              value: "[33.  0.  0.]",
              class: "0",
            },
          ],
          name: "Diameter \u2264 0.32",
          entropy: "0.556",
          samples: "54",
        },
      ],
      name: "Height \u2264 4.88",
      entropy: "1.0",
      samples: "98",
    },
    {
      id: "10",
      entropy: "0.235",
      samples: "52",
      value: "[ 2.  0. 50.]",
      class: "2",
    },
  ],
  name: "Diameter \u2264 0.45",
  entropy: "1.585",
  samples: "150",
};

// Perturbed data for grid of unstable decision trees
// Each perturbed data set contains:
// scatterData for the data points on the scatter plot
// splitRects for the decision boundaries on the scatter plot
// treeData to draw the tree
export const dataPerturbed = {
  data_0: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 2.9494958941177103,
        Diameter: 0.4133499426638638,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 5.401945172658548,
        Diameter: 0.3925712658993029,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 7.039428054431615,
        Diameter: 0.1938521586635549,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 7.725328706797699,
        Diameter: 0.272960727107866,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 3.8925901160952687,
        Diameter: 0.12751817208343386,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 2.5130873186563254,
        Diameter: 0.2269506516773156,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 7.572123672057082,
        Diameter: 0.4436866636490897,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.3875242749816232,
        Diameter: 0.31756244634064446,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.585886155036047,
        Diameter: 0.818702551577114,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 12.003398948581589,
        Diameter: 0.4921529271634439,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 10.64651452840952,
        Diameter: 0.43588075745985794,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 8.792774309412497,
        Diameter: 0.592927516749971,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.947590282120737,
        Diameter: 0.8793784790208186,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 9.340502063797416,
        Diameter: 0.5477997053852709,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              children: [
                {
                  id: "3",
                  entropy: "0.0",
                  samples: "42",
                  value: "[ 0. 42.  0.]",
                  class: "1",
                },
                {
                  id: "4",
                  entropy: "1.58",
                  samples: "3",
                  value: "[1. 1. 1.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.3",
              entropy: "0.0",
              samples: "45",
            },
            {
              id: "5",
              entropy: "0.56",
              samples: "53",
              value: "[46.  7.  0.]",
              class: "0",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "98",
        },
        {
          id: "10",
          children: [
            {
              id: "11",
              children: [
                {
                  id: "12",
                  entropy: "0.0",
                  samples: "3",
                  value: "[3. 0. 0.]",
                  class: "0",
                },
                {
                  id: "13",
                  entropy: "0.0",
                  samples: "4",
                  value: "[0. 0. 4.]",
                  class: "2",
                },
              ],
              name: "H \u2264 6.56",
              entropy: "1.0",
              samples: "7",
            },
            {
              id: "14",
              entropy: "0.0",
              samples: "45",
              value: "[ 0.  0. 45.]",
              class: "2",
            },
          ],
          name: "D \u2264 0.47",
          entropy: "0.0",
          samples: "52",
        },
      ],
      name: "D \u2264 0.44",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.3,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.3,
        x2: 0.44,
        y1: 4.88,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.0,
        x2: 0.44,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.44,
        x2: 0.47,
        y1: 6.56,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.44,
        x2: 0.47,
        y1: 16.0,
        y2: 6.56,
        prediction: "oak",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 16.0,
        y2: 0.0,
        prediction: "oak",
      },
    ],
  },
  data_1: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 5.401945172658548,
        Diameter: 0.3925712658993029,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.229569340148987,
        Diameter: 0.7024234090548709,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 8.296202576366554,
        Diameter: 0.321724705815554,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.746534235785484,
        Diameter: 0.4052257265853373,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 2.363992053429302,
        Diameter: 0.8660429680343644,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 1.7627421493621585,
        Diameter: 0.31957712325183485,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.585886155036047,
        Diameter: 0.818702551577114,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 12.003398948581589,
        Diameter: 0.4921529271634439,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 7.474699542764981,
        Diameter: 0.09724741132211612,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 4.984418800029208,
        Diameter: 0.6692129113729641,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 7.864043672934394,
        Diameter: 0.4706209249845933,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 8.966861936834283,
        Diameter: 0.18210306753470007,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              entropy: "0.16",
              samples: "43",
              value: "[ 1. 42.  0.]",
              class: "1",
            },
            {
              id: "5",
              children: [
                {
                  id: "6",
                  children: [
                    {
                      id: "7",
                      entropy: "1.0",
                      samples: "13",
                      value: "[6. 7. 0.]",
                      class: "1",
                    },
                    {
                      id: "8",
                      entropy: "0.72",
                      samples: "10",
                      value: "[8. 0. 2.]",
                      class: "0",
                    },
                  ],
                  name: "H \u2264 7.14",
                  entropy: "1.0",
                  samples: "23",
                },
                {
                  id: "9",
                  entropy: "0.0",
                  samples: "32",
                  value: "[32.  0.  0.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.32",
              entropy: "1.0",
              samples: "55",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "98",
        },
        {
          id: "10",
          children: [
            {
              id: "11",
              children: [
                {
                  id: "12",
                  entropy: "0.92",
                  samples: "3",
                  value: "[2. 0. 1.]",
                  class: "0",
                },
                {
                  id: "13",
                  entropy: "0.59",
                  samples: "19",
                  value: "[ 1.  1. 17.]",
                  class: "2",
                },
              ],
              name: "D \u2264 0.47",
              entropy: "1.0",
              samples: "22",
            },
            {
              id: "16",
              entropy: "0.0",
              samples: "30",
              value: "[ 0.  0. 30.]",
              class: "2",
            },
          ],
          name: "H \u2264 7.25",
          entropy: "0.0",
          samples: "52",
        },
      ],
      name: "D \u2264 0.45",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.45,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.32,
        y1: 7.14,
        y2: 4.88,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.32,
        y1: 16.0,
        y2: 7.14,
        prediction: "apple",
      },
      {
        x1: 0.32,
        x2: 0.45,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 0.47,
        y1: 7.25,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 7.25,
        y2: 0.0,
        prediction: "oak",
      },
      {
        x1: 0.45,
        x2: 1.3,
        y1: 16.0,
        y2: 7.25,
        prediction: "oak",
      },
    ],
  },
  data_2: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 9.187953313585862,
        Diameter: 0.45431608636843024,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 5.401945172658548,
        Diameter: 0.3925712658993029,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 6.637325469840594,
        Diameter: 0.3843695268210721,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 3.959084225855795,
        Diameter: 0.9205451699312864,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.008296301073072,
        Diameter: 0.8250198927114082,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 3.8925901160952687,
        Diameter: 0.12751817208343386,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 2.5130873186563254,
        Diameter: 0.2269506516773156,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.585886155036047,
        Diameter: 0.818702551577114,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 12.003398948581589,
        Diameter: 0.4921529271634439,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 6.937529790415973,
        Diameter: 0.8078985718071486,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 9.814265906674805,
        Diameter: 0.16541111832374988,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 9.940852193271844,
        Diameter: 0.9764332351265294,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.717932618001855,
        Diameter: 0.8114834828766271,
        Family: "oak",
      },
      {
        Height: 7.864043672934394,
        Diameter: 0.4706209249845933,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 9.460357920875564,
        Diameter: 0.07737117570947566,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              children: [
                {
                  id: "3",
                  entropy: "0.0",
                  samples: "42",
                  value: "[ 0. 42.  0.]",
                  class: "1",
                },
                {
                  id: "4",
                  entropy: "0.92",
                  samples: "3",
                  value: "[2. 1. 0.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.3",
              entropy: "0.0",
              samples: "45",
            },
            {
              id: "5",
              children: [
                {
                  id: "6",
                  children: [
                    {
                      id: "7",
                      entropy: "0.97",
                      samples: "10",
                      value: "[4. 6. 0.]",
                      class: "1",
                    },
                    {
                      id: "8",
                      entropy: "0.81",
                      samples: "8",
                      value: "[6. 0. 2.]",
                      class: "0",
                    },
                  ],
                  name: "H \u2264 7.14",
                  entropy: "1.0",
                  samples: "18",
                },
                {
                  id: "9",
                  entropy: "0.18",
                  samples: "37",
                  value: "[36.  0.  1.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.3",
              entropy: "1.0",
              samples: "55",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "100",
        },
        {
          id: "12",
          children: [
            {
              id: "13",
              children: [
                {
                  id: "14",
                  entropy: "0.0",
                  samples: "9",
                  value: "[0. 0. 9.]",
                  class: "2",
                },
                {
                  id: "15",
                  entropy: "1.52",
                  samples: "5",
                  value: "[2. 1. 2.]",
                  class: "0",
                },
              ],
              name: "H \u2264 3.82",
              entropy: "1.0",
              samples: "14",
            },
            {
              id: "16",
              entropy: "0.0",
              samples: "36",
              value: "[ 0.  0. 36.]",
              class: "2",
            },
          ],
          name: "H \u2264 6.01",
          entropy: "0.0",
          samples: "50",
        },
      ],
      name: "D \u2264 0.46",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.3,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.3,
        x2: 0.46,
        y1: 4.88,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.0,
        x2: 0.3,
        y1: 7.14,
        y2: 4.88,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.3,
        y1: 16.0,
        y2: 7.14,
        prediction: "apple",
      },
      {
        x1: 0.3,
        x2: 0.46,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.46,
        x2: 1.3,
        y1: 3.82,
        y2: 0.0,
        prediction: "oak",
      },
      {
        x1: 0.46,
        x2: 1.3,
        y1: 6.01,
        y2: 3.82,
        prediction: "apple",
      },
      {
        x1: 0.46,
        x2: 1.3,
        y1: 16.0,
        y2: 6.01,
        prediction: "oak",
      },
    ],
  },
  data_3: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 5.96852301926057,
        Diameter: 0.18410140770772912,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.8418269160401275,
        Diameter: 0.33418640161988056,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 6.637325469840594,
        Diameter: 0.3843695268210721,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 7.194278169660653,
        Diameter: 0.35618383016329563,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 3.8925901160952687,
        Diameter: 0.12751817208343386,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 1.9417241308396436,
        Diameter: 0.19865782936825516,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 2.5130873186563254,
        Diameter: 0.2269506516773156,
        Family: "cherry",
      },
      {
        Height: 3.3177280579850175,
        Diameter: 0.5737268583109583,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.585886155036047,
        Diameter: 0.818702551577114,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 13.107107195848162,
        Diameter: 0.8766728596272754,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 4.503680257852372,
        Diameter: 0.6266716728385681,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 7.864043672934394,
        Diameter: 0.4706209249845933,
        Family: "oak",
      },
      {
        Height: 11.91694370589688,
        Diameter: 0.12210890975198196,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 9.340502063797416,
        Diameter: 0.5477997053852709,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              entropy: "0.16",
              samples: "43",
              value: "[ 1. 42.  0.]",
              class: "1",
            },
            {
              id: "5",
              entropy: "0.68",
              samples: "55",
              value: "[47.  7.  1.]",
              class: "0",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "98",
        },
        {
          id: "10",
          entropy: "0.37",
          samples: "52",
          value: "[ 2.  1. 49.]",
          class: "2",
        },
      ],
      name: "D \u2264 0.45",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.45,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.45,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 1.3,
        y1: 16.0,
        y2: 0.0,
        prediction: "oak",
      },
    ],
  },
  data_4: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 5.401945172658548,
        Diameter: 0.3925712658993029,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.78751648768696,
        Diameter: 0.2048419188343764,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 6.637325469840594,
        Diameter: 0.3843695268210721,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.253779084735882,
        Diameter: 0.24867541515480876,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 7.717597268837455,
        Diameter: 0.7744541948294535,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 3.8925901160952687,
        Diameter: 0.12751817208343386,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.826301150302321,
        Diameter: 0.46934847168005767,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 2.594526916105125,
        Diameter: 0.5127973194263566,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.585886155036047,
        Diameter: 0.818702551577114,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 9.57537310473951,
        Diameter: 0.5623345828385233,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 12.003398948581589,
        Diameter: 0.4921529271634439,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 9.043461653402948,
        Diameter: 0.7090293127595493,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 5.941742584418927,
        Diameter: 0.9695354853179701,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 7.864043672934394,
        Diameter: 0.4706209249845933,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 9.340502063797416,
        Diameter: 0.5477997053852709,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              entropy: "0.16",
              samples: "42",
              value: "[ 1. 41.  0.]",
              class: "1",
            },
            {
              id: "5",
              entropy: "0.51",
              samples: "53",
              value: "[47.  6.  0.]",
              class: "0",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "95",
        },
        {
          id: "10",
          children: [
            {
              id: "11",
              children: [
                {
                  id: "12",
                  entropy: "0.92",
                  samples: "3",
                  value: "[2. 1. 0.]",
                  class: "0",
                },
                {
                  id: "13",
                  entropy: "0.0",
                  samples: "4",
                  value: "[0. 0. 4.]",
                  class: "2",
                },
              ],
              name: "H \u2264 6.56",
              entropy: "1.0",
              samples: "7",
            },
            {
              id: "14",
              entropy: "0.25",
              samples: "48",
              value: "[ 0.  2. 46.]",
              class: "2",
            },
          ],
          name: "D \u2264 0.47",
          entropy: "1.0",
          samples: "55",
        },
      ],
      name: "D \u2264 0.45",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.45,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.45,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 0.47,
        y1: 6.56,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 0.47,
        y1: 16.0,
        y2: 6.56,
        prediction: "oak",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 16.0,
        y2: 0.0,
        prediction: "oak",
      },
    ],
  },
  data_5: {
    scatterData: [
      {
        Height: 5.52104148380084,
        Diameter: 0.7736565416917762,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 5.401945172658548,
        Diameter: 0.3925712658993029,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.502676399936664,
        Diameter: 0.6961411142511547,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 8.933935965313337,
        Diameter: 0.19609262622239812,
        Family: "apple",
      },
      {
        Height: 11.035009460333098,
        Diameter: 0.974777530199894,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 6.637325469840594,
        Diameter: 0.3843695268210721,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 5.476279128925603,
        Diameter: 0.6391916443805464,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 2.5130873186563254,
        Diameter: 0.2269506516773156,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.585886155036047,
        Diameter: 0.818702551577114,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 10.973977530955857,
        Diameter: 0.2994336333331826,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.6512949658500675,
        Diameter: 0.4954442668036719,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 5.346309044519385,
        Diameter: 0.5604950741022647,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 7.864043672934394,
        Diameter: 0.4706209249845933,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 9.340502063797416,
        Diameter: 0.5477997053852709,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              entropy: "0.16",
              samples: "43",
              value: "[ 1. 42.  0.]",
              class: "1",
            },
            {
              id: "5",
              children: [
                {
                  id: "6",
                  children: [
                    {
                      id: "7",
                      entropy: "0.98",
                      samples: "12",
                      value: "[5. 7. 0.]",
                      class: "1",
                    },
                    {
                      id: "8",
                      entropy: "0.47",
                      samples: "10",
                      value: "[9. 0. 1.]",
                      class: "0",
                    },
                  ],
                  name: "H \u2264 7.14",
                  entropy: "1.0",
                  samples: "22",
                },
                {
                  id: "9",
                  entropy: "0.0",
                  samples: "30",
                  value: "[30.  0.  0.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.32",
              entropy: "1.0",
              samples: "52",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "95",
        },
        {
          id: "10",
          children: [
            {
              id: "11",
              children: [
                {
                  id: "12",
                  entropy: "0.37",
                  samples: "14",
                  value: "[ 1.  0. 13.]",
                  class: "2",
                },
                {
                  id: "15",
                  entropy: "0.92",
                  samples: "3",
                  value: "[2. 1. 0.]",
                  class: "0",
                },
              ],
              name: "H \u2264 5.41",
              entropy: "1.0",
              samples: "17",
            },
            {
              id: "16",
              entropy: "0.3",
              samples: "38",
              value: "[ 2.  0. 36.]",
              class: "2",
            },
          ],
          name: "H \u2264 6.01",
          entropy: "1.0",
          samples: "55",
        },
      ],
      name: "D \u2264 0.45",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.45,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.32,
        y1: 7.14,
        y2: 4.88,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.32,
        y1: 16.0,
        y2: 7.14,
        prediction: "apple",
      },
      {
        x1: 0.32,
        x2: 0.45,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 1.3,
        y1: 5.41,
        y2: 0.0,
        prediction: "oak",
      },
      {
        x1: 0.45,
        x2: 1.3,
        y1: 6.01,
        y2: 5.41,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 1.3,
        y1: 16.0,
        y2: 6.01,
        prediction: "oak",
      },
    ],
  },
  data_6: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 7.786999135105338,
        Diameter: 0.4162146742257026,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 7.5783578760247075,
        Diameter: 0.3329767205122587,
        Family: "apple",
      },
      {
        Height: 6.371144351490932,
        Diameter: 0.19628836292341878,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.735220833604087,
        Diameter: 0.33803794102265194,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 6.637325469840594,
        Diameter: 0.3843695268210721,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.194494791064825,
        Diameter: 0.3832607134883349,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.876353340867375,
        Diameter: 0.38796658236906634,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 3.8112134970155713,
        Diameter: 0.4596215713949335,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 3.8925901160952687,
        Diameter: 0.12751817208343386,
        Family: "cherry",
      },
      {
        Height: 4.67088683494714,
        Diameter: 0.6012554048511902,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.757258093276727,
        Diameter: 0.2000163992318788,
        Family: "cherry",
      },
      {
        Height: 2.5130873186563254,
        Diameter: 0.2269506516773156,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 4.391743264933844,
        Diameter: 0.18380905910070666,
        Family: "cherry",
      },
      {
        Height: 2.598430002715663,
        Diameter: 0.43383759649404774,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 10.5268858235758,
        Diameter: 0.36471731156867865,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 12.003398948581589,
        Diameter: 0.4921529271634439,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 7.864043672934394,
        Diameter: 0.4706209249845933,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 9.861459738053707,
        Diameter: 0.9749291373360676,
        Family: "oak",
      },
      {
        Height: 9.340502063797416,
        Diameter: 0.5477997053852709,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              children: [
                {
                  id: "3",
                  entropy: "0.0",
                  samples: "41",
                  value: "[ 0. 41.  0.]",
                  class: "1",
                },
                {
                  id: "4",
                  entropy: "1.0",
                  samples: "2",
                  value: "[1. 1. 0.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.32",
              entropy: "0.0",
              samples: "43",
            },
            {
              id: "5",
              entropy: "0.63",
              samples: "54",
              value: "[47.  6.  1.]",
              class: "0",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "97",
        },
        {
          id: "12",
          children: [
            {
              id: "13",
              children: [
                {
                  id: "14",
                  entropy: "0.92",
                  samples: "3",
                  value: "[2. 1. 0.]",
                  class: "0",
                },
                {
                  id: "15",
                  entropy: "0.0",
                  samples: "4",
                  value: "[0. 0. 4.]",
                  class: "2",
                },
              ],
              name: "H \u2264 6.56",
              entropy: "1.0",
              samples: "7",
            },
            {
              id: "16",
              children: [
                {
                  id: "17",
                  children: [
                    {
                      id: "18",
                      entropy: "0.0",
                      samples: "8",
                      value: "[0. 0. 8.]",
                      class: "2",
                    },
                    {
                      id: "19",
                      entropy: "1.0",
                      samples: "2",
                      value: "[0. 1. 1.]",
                      class: "1",
                    },
                  ],
                  name: "H \u2264 3.67",
                  entropy: "0.0",
                  samples: "10",
                },
                {
                  id: "20",
                  entropy: "0.0",
                  samples: "36",
                  value: "[ 0.  0. 36.]",
                  class: "2",
                },
              ],
              name: "H \u2264 4.81",
              entropy: "0.0",
              samples: "46",
            },
          ],
          name: "D \u2264 0.47",
          entropy: "0.0",
          samples: "53",
        },
      ],
      name: "D \u2264 0.45",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.32,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.32,
        x2: 0.45,
        y1: 4.88,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.0,
        x2: 0.45,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 0.47,
        y1: 6.56,
        y2: 0.0,
        prediction: "apple",
      },
      {
        x1: 0.45,
        x2: 0.47,
        y1: 16.0,
        y2: 6.56,
        prediction: "oak",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 3.67,
        y2: 0.0,
        prediction: "oak",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 4.81,
        y2: 3.67,
        prediction: "cherry",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 16.0,
        y2: 4.81,
        prediction: "oak",
      },
    ],
  },
  data_7: {
    scatterData: [
      {
        Height: 5.378134684276249,
        Diameter: 0.2837053102864515,
        Family: "apple",
      },
      {
        Height: 7.026770998894443,
        Diameter: 0.33207590604763576,
        Family: "apple",
      },
      {
        Height: 8.315904765022157,
        Diameter: 0.43373473004096097,
        Family: "apple",
      },
      {
        Height: 3.6498932725961946,
        Diameter: 0.340192927284109,
        Family: "apple",
      },
      {
        Height: 6.5075162679197005,
        Diameter: 0.4208133542045584,
        Family: "apple",
      },
      {
        Height: 8.348660609314411,
        Diameter: 0.2899813396901569,
        Family: "apple",
      },
      {
        Height: 9.421950227722382,
        Diameter: 0.20528286046244248,
        Family: "apple",
      },
      {
        Height: 7.896332074805976,
        Diameter: 0.2760452936212325,
        Family: "apple",
      },
      {
        Height: 8.991058052538946,
        Diameter: 0.323141279464649,
        Family: "apple",
      },
      {
        Height: 8.779807373618395,
        Diameter: 0.2794179616731622,
        Family: "apple",
      },
      {
        Height: 6.224027369482324,
        Diameter: 0.3377198782721952,
        Family: "apple",
      },
      {
        Height: 6.899926967452563,
        Diameter: 0.9438985393730742,
        Family: "apple",
      },
      {
        Height: 8.577082057259027,
        Diameter: 0.3920555558040337,
        Family: "apple",
      },
      {
        Height: 8.261925111321437,
        Diameter: 0.2929926034845541,
        Family: "apple",
      },
      {
        Height: 5.401945172658548,
        Diameter: 0.3925712658993029,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.306514509007707,
        Diameter: 0.2692666806586824,
        Family: "apple",
      },
      {
        Height: 5.979720750245472,
        Diameter: 0.4303423671955017,
        Family: "apple",
      },
      {
        Height: 8.501834143773902,
        Diameter: 0.4252007951459287,
        Family: "apple",
      },
      {
        Height: 9.630221870082979,
        Diameter: 0.8827933308581433,
        Family: "apple",
      },
      {
        Height: 9.849267838333237,
        Diameter: 0.3934263382447983,
        Family: "apple",
      },
      {
        Height: 9.82242930026342,
        Diameter: 0.3544905997693495,
        Family: "apple",
      },
      {
        Height: 8.126177454200544,
        Diameter: 0.4158384944385092,
        Family: "apple",
      },
      {
        Height: 7.363172332975688,
        Diameter: 0.4040126228810647,
        Family: "apple",
      },
      {
        Height: 8.092656815557994,
        Diameter: 0.3340260447537869,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 7.750236378926506,
        Diameter: 0.3663964609236702,
        Family: "apple",
      },
      {
        Height: 5.732788443606039,
        Diameter: 0.4488555701323009,
        Family: "apple",
      },
      {
        Height: 7.605071013709472,
        Diameter: 0.4097062530380759,
        Family: "apple",
      },
      {
        Height: 5.471691020402937,
        Diameter: 0.2193367411597629,
        Family: "apple",
      },
      {
        Height: 8.410034944656987,
        Diameter: 0.4347781549566119,
        Family: "apple",
      },
      {
        Height: 6.637325469840594,
        Diameter: 0.3843695268210721,
        Family: "apple",
      },
      {
        Height: 4.432164474873146,
        Diameter: 0.4517772834518525,
        Family: "apple",
      },
      {
        Height: 6.178733142922798,
        Diameter: 0.9720975112939371,
        Family: "apple",
      },
      {
        Height: 5.989321892924825,
        Diameter: 0.4710700019892728,
        Family: "apple",
      },
      {
        Height: 8.52220649983437,
        Diameter: 0.2904503960247318,
        Family: "apple",
      },
      {
        Height: 8.98895597681797,
        Diameter: 0.3883776426016339,
        Family: "apple",
      },
      {
        Height: 7.230096142836385,
        Diameter: 0.3135821886374153,
        Family: "apple",
      },
      {
        Height: 7.5010032985521935,
        Diameter: 0.2367375937610627,
        Family: "apple",
      },
      {
        Height: 7.49576305209169,
        Diameter: 0.3475480209305752,
        Family: "apple",
      },
      {
        Height: 8.411007148238344,
        Diameter: 0.3798569219078376,
        Family: "apple",
      },
      {
        Height: 7.212326690968554,
        Diameter: 0.29644675976749674,
        Family: "apple",
      },
      {
        Height: 6.424278009108987,
        Diameter: 0.3750560316196041,
        Family: "apple",
      },
      {
        Height: 6.905481940324154,
        Diameter: 0.3517647873909887,
        Family: "apple",
      },
      {
        Height: 5.378071819273013,
        Diameter: 0.37397028012946376,
        Family: "apple",
      },
      {
        Height: 7.2809848018346655,
        Diameter: 0.35450359943840504,
        Family: "apple",
      },
      {
        Height: 7.204259921203327,
        Diameter: 0.4071277510533611,
        Family: "apple",
      },
      {
        Height: 4.921892414532857,
        Diameter: 0.3017574369773133,
        Family: "apple",
      },
      {
        Height: 6.984723580549742,
        Diameter: 0.41900967381187904,
        Family: "apple",
      },
      {
        Height: 4.958440299138612,
        Diameter: 0.2743102365785393,
        Family: "apple",
      },
      {
        Height: 6.826826364216133,
        Diameter: 0.19568658345953274,
        Family: "cherry",
      },
      {
        Height: 5.065276092143593,
        Diameter: 0.2887589999228476,
        Family: "cherry",
      },
      {
        Height: 4.175110869129972,
        Diameter: 0.2489974288615531,
        Family: "cherry",
      },
      {
        Height: 7.074215361800091,
        Diameter: 0.2401139133631077,
        Family: "cherry",
      },
      {
        Height: 3.504352636035516,
        Diameter: 0.21265902403110112,
        Family: "cherry",
      },
      {
        Height: 3.931601796372556,
        Diameter: 0.19385340552861086,
        Family: "cherry",
      },
      {
        Height: 2.959438941134493,
        Diameter: 0.21101741453335784,
        Family: "cherry",
      },
      {
        Height: 1.3531707739069665,
        Diameter: 0.15202589008824116,
        Family: "cherry",
      },
      {
        Height: 2.9274792177104287,
        Diameter: 0.2371221128734013,
        Family: "cherry",
      },
      {
        Height: 2.073448949712919,
        Diameter: 0.2008115885122318,
        Family: "cherry",
      },
      {
        Height: 3.115585938289557,
        Diameter: 0.2252644818572057,
        Family: "cherry",
      },
      {
        Height: 1.5074707027897296,
        Diameter: 0.1571428571245346,
        Family: "cherry",
      },
      {
        Height: 1.5154725718921578,
        Diameter: 0.11853452060967162,
        Family: "cherry",
      },
      {
        Height: 3.3844347545425566,
        Diameter: 0.2513000434586389,
        Family: "cherry",
      },
      {
        Height: 3.5235809697276257,
        Diameter: 0.19022830439439464,
        Family: "cherry",
      },
      {
        Height: 5.426264791051264,
        Diameter: 0.3139717250649179,
        Family: "cherry",
      },
      {
        Height: 3.94276337443783,
        Diameter: 0.18551767355748724,
        Family: "cherry",
      },
      {
        Height: 2.433817911566308,
        Diameter: 0.04309305046589432,
        Family: "cherry",
      },
      {
        Height: 3.861252157184413,
        Diameter: 0.10801291473337032,
        Family: "cherry",
      },
      {
        Height: 0.3642815418538148,
        Diameter: 0.09476508086494977,
        Family: "cherry",
      },
      {
        Height: 2.2969700138355917,
        Diameter: 0.1982897180086732,
        Family: "cherry",
      },
      {
        Height: 3.8925901160952687,
        Diameter: 0.12751817208343386,
        Family: "cherry",
      },
      {
        Height: 5.4599721608237255,
        Diameter: 0.2340065028283703,
        Family: "cherry",
      },
      {
        Height: 1.9531075997484997,
        Diameter: 0.10051743577828756,
        Family: "cherry",
      },
      {
        Height: 2.773729825320483,
        Diameter: 0.1994289061935656,
        Family: "cherry",
      },
      {
        Height: 3.6782932052493305,
        Diameter: 0.1673273776698777,
        Family: "cherry",
      },
      {
        Height: 5.169407927240174,
        Diameter: 0.2239210950462592,
        Family: "cherry",
      },
      {
        Height: 2.795045914244948,
        Diameter: 0.30311633433841306,
        Family: "cherry",
      },
      {
        Height: 4.841722458402736,
        Diameter: 0.2211556047130321,
        Family: "cherry",
      },
      {
        Height: 3.5725463559332185,
        Diameter: 0.28520802676934315,
        Family: "cherry",
      },
      {
        Height: 3.4137423619915466,
        Diameter: 0.260347239443432,
        Family: "cherry",
      },
      {
        Height: 3.152548047623573,
        Diameter: 0.08844164384204815,
        Family: "cherry",
      },
      {
        Height: 2.4007303551539363,
        Diameter: 0.1182317310962694,
        Family: "cherry",
      },
      {
        Height: 3.4017360151144023,
        Diameter: 0.3047867286857835,
        Family: "cherry",
      },
      {
        Height: 1.905556279838584,
        Diameter: 0.12056707362043716,
        Family: "cherry",
      },
      {
        Height: 3.3759817239673984,
        Diameter: 0.12915788665196262,
        Family: "cherry",
      },
      {
        Height: 2.829831023475418,
        Diameter: 0.1936803347539328,
        Family: "cherry",
      },
      {
        Height: 2.7475307491294645,
        Diameter: 0.18853204879992685,
        Family: "cherry",
      },
      {
        Height: 3.8436354572087694,
        Diameter: 0.2604042284939499,
        Family: "cherry",
      },
      {
        Height: 4.608487593711247,
        Diameter: 0.20136528630476572,
        Family: "cherry",
      },
      {
        Height: 1.964452064584164,
        Diameter: 0.2189140901790427,
        Family: "cherry",
      },
      {
        Height: 5.7468323469296925,
        Diameter: 0.299745004207425,
        Family: "cherry",
      },
      {
        Height: 1.9735168750992964,
        Diameter: 0.9819433894758831,
        Family: "cherry",
      },
      {
        Height: 2.5130873186563254,
        Diameter: 0.2269506516773156,
        Family: "cherry",
      },
      {
        Height: 3.927015263919292,
        Diameter: 0.19422445207977626,
        Family: "cherry",
      },
      {
        Height: 2.8472092579223833,
        Diameter: 0.10098289382131112,
        Family: "cherry",
      },
      {
        Height: 4.01046579771699,
        Diameter: 0.1912596593865531,
        Family: "cherry",
      },
      {
        Height: 4.561842693058842,
        Diameter: 0.21209319101039825,
        Family: "cherry",
      },
      {
        Height: 1.921243710658904,
        Diameter: 0.1972398426723843,
        Family: "cherry",
      },
      {
        Height: 2.3807114937249607,
        Diameter: 0.17469612837406315,
        Family: "cherry",
      },
      {
        Height: 8.393851039719372,
        Diameter: 0.4706168851814446,
        Family: "oak",
      },
      {
        Height: 13.0836550644812,
        Diameter: 0.5630717411309293,
        Family: "oak",
      },
      {
        Height: 3.652526786406281,
        Diameter: 0.8572696732141827,
        Family: "oak",
      },
      {
        Height: 10.609924889061622,
        Diameter: 0.8094630719246008,
        Family: "oak",
      },
      {
        Height: 10.766935120680039,
        Diameter: 1.0030103882834824,
        Family: "oak",
      },
      {
        Height: 11.319732617623506,
        Diameter: 0.36023174064378627,
        Family: "oak",
      },
      {
        Height: 3.362396467024424,
        Diameter: 0.7441798977354616,
        Family: "oak",
      },
      {
        Height: 3.5773458846406223,
        Diameter: 0.5565087271923822,
        Family: "oak",
      },
      {
        Height: 11.470997466737554,
        Diameter: 0.9434900517744736,
        Family: "oak",
      },
      {
        Height: 7.1340941717084645,
        Diameter: 0.45034198652389534,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 11.155333188250728,
        Diameter: 0.7381067384039341,
        Family: "oak",
      },
      {
        Height: 12.003398948581589,
        Diameter: 0.4921529271634439,
        Family: "oak",
      },
      {
        Height: 3.314760353468839,
        Diameter: 0.5527175232488952,
        Family: "oak",
      },
      {
        Height: 6.9525372257303975,
        Diameter: 0.6505191539262914,
        Family: "oak",
      },
      {
        Height: 14.308553948019547,
        Diameter: 0.4595230403418795,
        Family: "oak",
      },
      {
        Height: 5.318455859550399,
        Diameter: 0.6358755271977429,
        Family: "oak",
      },
      {
        Height: 8.994619858015309,
        Diameter: 0.9651364379507832,
        Family: "oak",
      },
      {
        Height: 8.078812414828402,
        Diameter: 0.9282895060879556,
        Family: "oak",
      },
      {
        Height: 4.9547992790837965,
        Diameter: 0.7868729371518406,
        Family: "oak",
      },
      {
        Height: 8.931685913355011,
        Diameter: 0.5205977935304034,
        Family: "oak",
      },
      {
        Height: 5.190725035438217,
        Diameter: 0.5604360140691054,
        Family: "oak",
      },
      {
        Height: 6.360206365465713,
        Diameter: 0.7841339180006817,
        Family: "oak",
      },
      {
        Height: 6.9987725888175145,
        Diameter: 0.87953525916728,
        Family: "oak",
      },
      {
        Height: 6.798935852859628,
        Diameter: 0.4777269986924233,
        Family: "oak",
      },
      {
        Height: 7.319591483488012,
        Diameter: 0.8969869229949456,
        Family: "oak",
      },
      {
        Height: 10.13716832344025,
        Diameter: 0.5038617070681707,
        Family: "oak",
      },
      {
        Height: 3.4394512021969,
        Diameter: 0.5463333491486161,
        Family: "oak",
      },
      {
        Height: 7.688511914462794,
        Diameter: 0.5018764223796339,
        Family: "oak",
      },
      {
        Height: 6.02251194007707,
        Diameter: 0.7468364003689868,
        Family: "oak",
      },
      {
        Height: 3.1136905750870767,
        Diameter: 0.7680268494456803,
        Family: "oak",
      },
      {
        Height: 3.2178521278717245,
        Diameter: 0.6041827152002488,
        Family: "oak",
      },
      {
        Height: 7.820712401319972,
        Diameter: 0.8373904607420403,
        Family: "oak",
      },
      {
        Height: 10.336012182523184,
        Diameter: 0.8864125069166998,
        Family: "oak",
      },
      {
        Height: 14.03734728401795,
        Diameter: 0.6391162313019731,
        Family: "oak",
      },
      {
        Height: 7.068698596298202,
        Diameter: 0.49088943260977796,
        Family: "oak",
      },
      {
        Height: 11.321980826168046,
        Diameter: 0.7892516270462221,
        Family: "oak",
      },
      {
        Height: 11.642967839528112,
        Diameter: 0.5446381743548243,
        Family: "oak",
      },
      {
        Height: 3.685530543208692,
        Diameter: 0.5013497707282457,
        Family: "oak",
      },
      {
        Height: 11.976932228515894,
        Diameter: 0.6774724184486708,
        Family: "oak",
      },
      {
        Height: 11.563539032354994,
        Diameter: 0.5580426173913396,
        Family: "oak",
      },
      {
        Height: 3.3539553088448937,
        Diameter: 0.8054232187468592,
        Family: "oak",
      },
      {
        Height: 10.158280227696151,
        Diameter: 0.9705464856167308,
        Family: "oak",
      },
      {
        Height: 12.172466643630367,
        Diameter: 0.6108372959361905,
        Family: "oak",
      },
      {
        Height: 6.61305301568547,
        Diameter: 0.44541105894947947,
        Family: "oak",
      },
      {
        Height: 12.999214026430275,
        Diameter: 0.9603531223531292,
        Family: "oak",
      },
      {
        Height: 10.13736347650017,
        Diameter: 0.8554993487517485,
        Family: "oak",
      },
      {
        Height: 10.626915145665945,
        Diameter: 0.5121061048537965,
        Family: "oak",
      },
      {
        Height: 9.340502063797416,
        Diameter: 0.5477997053852709,
        Family: "oak",
      },
      {
        Height: 7.2765669488404345,
        Diameter: 0.7063986424618351,
        Family: "oak",
      },
    ],
    maxDepth: 4,
    treeData: {
      id: "0",
      children: [
        {
          id: "1",
          children: [
            {
              id: "2",
              entropy: "0.16",
              samples: "43",
              value: "[ 1. 42.  0.]",
              class: "1",
            },
            {
              id: "5",
              children: [
                {
                  id: "6",
                  children: [
                    {
                      id: "7",
                      entropy: "1.0",
                      samples: "13",
                      value: "[6. 7. 0.]",
                      class: "1",
                    },
                    {
                      id: "8",
                      entropy: "0.0",
                      samples: "9",
                      value: "[9. 0. 0.]",
                      class: "0",
                    },
                  ],
                  name: "H \u2264 7.14",
                  entropy: "1.0",
                  samples: "22",
                },
                {
                  id: "9",
                  entropy: "0.22",
                  samples: "29",
                  value: "[28.  0.  1.]",
                  class: "0",
                },
              ],
              name: "D \u2264 0.32",
              entropy: "1.0",
              samples: "51",
            },
          ],
          name: "H \u2264 4.88",
          entropy: "1.0",
          samples: "94",
        },
        {
          id: "12",
          children: [
            {
              id: "13",
              children: [
                {
                  id: "14",
                  entropy: "0.47",
                  samples: "10",
                  value: "[0. 1. 9.]",
                  class: "2",
                },
                {
                  id: "17",
                  children: [
                    {
                      id: "18",
                      entropy: "0.81",
                      samples: "4",
                      value: "[3. 0. 1.]",
                      class: "0",
                    },
                    {
                      id: "19",
                      entropy: "0.81",
                      samples: "8",
                      value: "[2. 0. 6.]",
                      class: "2",
                    },
                  ],
                  name: "D \u2264 0.47",
                  entropy: "1.0",
                  samples: "12",
                },
              ],
              name: "H \u2264 4.06",
              entropy: "1.0",
              samples: "22",
            },
            {
              id: "20",
              entropy: "0.19",
              samples: "34",
              value: "[ 1.  0. 33.]",
              class: "2",
            },
          ],
          name: "H \u2264 6.93",
          entropy: "1.0",
          samples: "56",
        },
      ],
      name: "D \u2264 0.44",
      entropy: "2.0",
      samples: "150",
    },
    splitRects: [
      {
        x1: 0.0,
        x2: 0.44,
        y1: 4.88,
        y2: 0.0,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.32,
        y1: 7.14,
        y2: 4.88,
        prediction: "cherry",
      },
      {
        x1: 0.0,
        x2: 0.32,
        y1: 16.0,
        y2: 7.14,
        prediction: "apple",
      },
      {
        x1: 0.32,
        x2: 0.44,
        y1: 16.0,
        y2: 4.88,
        prediction: "apple",
      },
      {
        x1: 0.44,
        x2: 1.3,
        y1: 4.06,
        y2: 0.0,
        prediction: "oak",
      },
      {
        x1: 0.44,
        x2: 0.47,
        y1: 6.93,
        y2: 4.06,
        prediction: "apple",
      },
      {
        x1: 0.47,
        x2: 1.3,
        y1: 6.93,
        y2: 4.06,
        prediction: "oak",
      },
      {
        x1: 0.44,
        x2: 1.3,
        y1: 16.0,
        y2: 6.93,
        prediction: "oak",
      },
    ],
  },
};
